import {curry} from 'ramda';
import {ApplicationState} from './store';
import {connect, shallowEqual, useSelector} from 'react-redux';
import {Language} from './constants/language';

// noinspection SpellCheckingInspection
const i18n = {
    'accessibility.buttonLabel.en': 'Show accessibility information',
    'accessibility.buttonLabel.no': 'Vis tilgjengelighetsinformasjon',
    'accessibility.buttonLabel.sv': 'Visa tillgänglighetsinformation',
    'accessibility.info.en': 'Accessibility information',
    'accessibility.info.no': 'Tilgjengelighetsinformasjon',
    'accessibility.info.sv': 'Tillgänglighetsinformation',
    'accessibility.label.en': 'Accessibility',
    'accessibility.label.no': 'Tilgjengelighet',
    'accessibility.label.sv': 'Tillgänglighet',
    'accessibility.homepage.en': 'Homepage',
    'accessibility.homepage.no': 'Startside',
    'accessibility.homepage.sv': 'Startsida',
    'accessibility.search.en': 'Individual search bar',
    'accessibility.search.no': 'Individsøk',
    'accessibility.search.sv': 'Individsök',
    'accessibility.fromMenu.en': 'from Menu',
    'accessibility.fromMenu.no': 'fra Meny',
    'accessibility.fromMenu.sv': 'från Meny',
    'accessibility.shortcuts.en': 'Shortcuts',
    'accessibility.shortcuts.no': 'Snarveier',
    'accessibility.shortcuts.sv': 'Genvägar',
    'accessibility.shortcut.en': 'shortcut',
    'accessibility.shortcut.no': 'hurtigtast',
    'accessibility.shortcut.sv': 'genväg',
    'accessibility.command.en': 'Access keys',
    'accessibility.command.no': 'Hurtigkommandoer',
    'accessibility.command.sv': 'Kortkommandon',
    'accessibility.command.p1.en': 'There are other shortcuts, accesskeys, available to ease the navigation on the website. Different browsers use different keystrokes to activate accesskey shortcuts.',
    'accessibility.command.p1.no': 'Hurtigkommandoer skal gjøre det mer effektivt å bruke siden, spesielt for de som er ofte inne på nettstedet. Hvordan hurtigkommandoene brukes avhenger av nettleser.',
    'accessibility.command.p1.sv': 'Sättet att nå dessa genvägar skiljer sig lite beroende på vilken webbläsare du använder.',
    'accessibility.skipToContent.en': 'Skip to content',
    'accessibility.skipToContent.no': 'Hopp til hovedinnhold',
    'accessibility.skipToContent.sv': 'Hoppa till huvudinnehåll',
    'accessibility.skipFilter.en': 'Skip filters',
    'accessibility.skipFilter.no': 'Hopp over filter',
    'accessibility.skipFilter.sv': 'Hoppa över filter',
    'accessibility.shortcuts.p1.en': 'There is a skip button to the carnivore filter that can be accessed with tab navigation from the top of the page.',
    'accessibility.shortcuts.p1.no': 'Snarvei til filtrering av rovdyr finnes ved tab-navigering fra toppen av søkesiden.',
    'accessibility.shortcuts.p1.sv': 'Högst upp på sidan finns en genväg till filtrering av rovdjursarter. Genvägen nås genom att använda tab-tangenten från toppen av sidan. ',
    'accessibility.tips.en': 'Accessibility tips and tricks',
    'accessibility.tips.no': 'Tips og triks',
    'accessibility.tips.sv': 'Tips och tricks',
    'accessibility.contentSETitle.en': 'Accessibility statement for rovbase.se',
    'accessibility.contentSETitle.no': 'Tilgjengelighetserklæring for rovbase.se',
    'accessibility.contentSETitle.sv': 'Tillgänglighet för Rovbase.se',
    'accessibility.contentNOTitle.en': 'Accessibility statement for rovbase.no',
    'accessibility.contentNOTitle.no': 'Tilgjengelighetserklæring for rovbase.no',
    'accessibility.contentNOTitle.sv': 'Tillgänglighet för Rovbase.no',    
    'ariaLabel.geographySearch.en': 'Geography search',
    'ariaLabel.geographySearch.no': 'Geografisøk',
    'ariaLabel.geographySearch.sv': 'Geografiskt sökområde',
    'ariaLabel.link.en': 'Link to ',
    'ariaLabel.link.no': 'Lenke til ',
    'ariaLabel.link.sv': 'Länk till ',
    'ariaLabel.mailLink.en': 'Send link to ',
    'ariaLabel.mailLink.no': 'Send lenke til ',
    'ariaLabel.mailLink.sv': 'Skicka länk till ',
    'ariaLabel.openHomepage.en': 'To home page',
    'ariaLabel.openHomepage.no': 'Til startsiden',
    'ariaLabel.openHomepage.sv': 'Till startsidan',
    'ariaLabel.search.en': 'Search',
    'ariaLabel.search.no': 'søk',
    'ariaLabel.search.sv': 'sök',
    'ariaLabel.openCalendar.en': 'Open calendar',
    'ariaLabel.openCalendar.no': 'Åpne datovelger',
    'ariaLabel.openCalendar.sv': 'öppna kalender',
    'button.selectAll.en': 'Select all',
    'button.selectAll.no': 'Velg alle',
    'button.selectAll.sv': 'Välj alla',
    'button.selectNone.en': 'Select none',
    'button.selectNone.no': 'Velg ingen',
    'button.selectNone.sv': 'Välj ingen',
    'carnivore.bear.en': 'Bear',
    'carnivore.bear.no': 'Bjørn',
    'carnivore.bear.sv': 'Björn',
    'carnivore.eagle.en': 'Golden Eagle',
    'carnivore.eagle.no': 'Kongeørn',
    'carnivore.eagle.sv': 'Kungsörn',
    'carnivore.goldenEagle.en': 'Golden Eagle',
    'carnivore.goldenEagle.no': 'Kongeørn',
    'carnivore.goldenEagle.sv': 'Kungsörn',
    'carnivore.lynx.en': 'Lynx',
    'carnivore.lynx.no': 'Gaupe',
    'carnivore.lynx.sv': 'Lodjur',
    'carnivore.unknownProtected.en': 'Unknown protected carnivore',
    'carnivore.unknownProtected.no': 'Ukjent fredet rovdyr',
    'carnivore.unknownProtected.sv': 'Okänt fredat rovdjur',
    'carnivore.wolf.en': 'Wolf',
    'carnivore.wolf.no': 'Ulv',
    'carnivore.wolf.sv': 'Varg',
    'carnivore.wolverine.en': 'Wolverine',
    'carnivore.wolverine.no': 'Jerv',
    'carnivore.wolverine.sv': 'Järv',
    'carnivoreDamage.cattle.en': 'Cattle',
    'carnivoreDamage.cattle.no': 'Storfe',
    'carnivoreDamage.cattle.sv': 'Nötkreatur',
    'carnivoreDamage.dog.en': 'Dog',
    'carnivoreDamage.dog.no': 'Hund',
    'carnivoreDamage.dog.sv': 'Hund',
    'carnivoreDamage.goat.en': 'Goat',
    'carnivoreDamage.goat.no': 'Geit',
    'carnivoreDamage.goat.sv': 'Get',
    'carnivoreDamage.reindeer.en': 'Reindeer',
    'carnivoreDamage.reindeer.no': 'Rein',
    'carnivoreDamage.reindeer.sv': 'Ren',
    'carnivoreDamage.sheep.en': 'Sheep',
    'carnivoreDamage.sheep.no': 'Sau',
    'carnivoreDamage.sheep.sv': 'Får',
    'carnivoreDamage.infoMessage.en': 'Only damages on reindeer are shown in Sweden. More information in ',
    'carnivoreDamage.infoMessage.no': 'I Sverige vises kun rovviltskader på rein. Les mer på ',
    'carnivoreDamage.infoMessage.sv': 'I Sverige visas endast rovdjursskador på ren. Läs mer under ',
    'carnivoreDamage.infoMessage2.en': 'See also ',
    'carnivoreDamage.infoMessage2.no': 'Se også ',
    'carnivoreDamage.infoMessage2.sv': 'Se även ',
    'carnivoreFilter.carnivore.en': 'Carnivores',
    'carnivoreFilter.carnivore.no': 'Rovdyr',
    'carnivoreFilter.carnivore.sv': 'Rovdjur',
    'carnivoreObserved.bear.en': 'Bear',
    'carnivoreObserved.bear.no': 'Bjørn',
    'carnivoreObserved.bear.sv': 'Björn',
    'carnivoreObserved.eagle.en': 'Golden Eagle',
    'carnivoreObserved.eagle.no': 'Kongeørn',
    'carnivoreObserved.eagle.sv': 'Kungsörn',
    'carnivoreObserved.lynx.en': 'Lynx',
    'carnivoreObserved.lynx.no': 'Gaupe',
    'carnivoreObserved.lynx.sv': 'Lodjur',
    'carnivoreObserved.unknown.en': 'Unknown',
    'carnivoreObserved.unknown.no': 'Ukjent',
    'carnivoreObserved.unknown.sv': 'Okänd',
    'carnivoreObserved.wolf.en': 'Wolf',
    'carnivoreObserved.wolf.no': 'Ulv',
    'carnivoreObserved.wolf.sv': 'Varg',
    'carnivoreObserved.wolverine.en': 'Wolverine',
    'carnivoreObserved.wolverine.no': 'Jerv',
    'carnivoreObserved.wolverine.sv': 'Järv',
    'carnivoreObserved.redFox.en': 'Red fox',
    'carnivoreObserved.redFox.no': 'Rødrev',
    'carnivoreObserved.redFox.sv': 'Rödräv',
    'carnivoreObserved.arcticFox.en': 'Arctic fox',
    'carnivoreObserved.arcticFox.no': 'Fjellrev',
    'carnivoreObserved.arcticFox.sv': 'Fjällräv',
    'carnivoreObserved.dog.en': 'Dog',
    'carnivoreObserved.dog.no': 'Hund',
    'carnivoreObserved.dog.sv': 'Hund',
    'carnivoreObserved.mink.en': 'Mink',
    'carnivoreObserved.mink.no': 'Mink',
    'carnivoreObserved.mink.sv': 'Mink',
    'carnivoreObserved.marten.en': 'Pine marten',
    'carnivoreObserved.marten.no': 'Mår',
    'carnivoreObserved.marten.sv': 'Mård',
    'contact.visitAddress.en': 'Visiting address',
    'contact.visitAddress.no': 'Besøksadresse',
    'contact.visitAddress.sv': 'Besöksadress',
    'contact.postAddress.en': 'Mail address',
    'contact.postAddress.no': 'Postadresse',
    'contact.postAddress.sv': 'Postadress',
    'contact.panel1.header.en': 'Report information about carnivores or sheep or reindeer carcasses',
    'contact.panel1.header.no': 'Meld inn opplysninger om rovvilt eller kadaver av sau eller rein',
    'contact.panel1.header.sv': 'Lämna upplysningar om rovdjur eller skador på tamdjur',
    'contact.panel2.header.en': 'More information about compensation for sheep or reindeer losses',
    'contact.panel2.header.no': 'Mer informasjon om erstatning for sau eller rein i ditt fylke',
    'contact.panel2.header.sv': 'Mer information om ersättning för skador på tamdjur',
    'contact.panel3.header.en': 'More information about carnivores',
    'contact.panel3.header.no': 'Mer informasjon om rovvilt',
    'contact.panel3.header.sv': 'Mer information om rovdjuren',
    'contact.panel1.en':
        'If you have information about carnivores or sheep and/or semi-domesticated reindeer carcasses, please contact ',
    'contact.panel1.no': 'Om du har opplysninger om rovvilt eller kadaver av sau eller rein ber vi deg ta kontakt med ',
    'contact.panel1.sv': 'Om du har sett ett rovdjur eller spår av rovdjur eller hittat kadaver av tamdjur kontakta',
    'contact.panel2.en':
        'For more information about compensation for sheep and/or semi-domesticated reindeer losses in your county, please contact ',
    'contact.panel2.no': 'For mer informasjon om erstatning for sau eller rein i ditt fylke ta kontakt med ',
    'contact.panel2.sv': 'För mer information om ersättning för tamdjur kontakta ',
    'contact.panel3-1.en': 'More information about carnivores in Norway is available (in Norwegian) at',
    'contact.panel3-1.no': 'Mer informasjon om rovvilt i Norge finner du ',
    'contact.panel3-1.sv': 'Mer information om rovdjuren i Norge hittar du ',
    'contact.panel3-2.en': 'Information about carnivores in Sweden is available (in Swedish) at',
    'contact.panel3-2.no': 'Informasjon om rovvilt i Sverige finner du ',
    'contact.panel3-2.sv': 'Information om rovdjuren i Sverige hittar du på ',
    'contact.p3-2.en':
        'For comprehensive information about carnivores in Norway, or if you have questions about this web service, please contact ',
    'contact.p3-2.no': 'Ved spørsmål om Rovbase.no, eller overordnet informasjon om rovvilt, kontakt ',
    'contact.p3-2.sv': 'För övergripande information om rovdjur kontakta ',
    'contact.or.en': 'or',
    'contact.or.no': 'eller',
    'contact.or.sv': 'eller',
    'contact.MDIR.en': 'Norwegian Environment Agency',
    'contact.MDIR.no': 'Miljødirektoratet',
    'contact.MDIR.sv': 'Miljødirektoratet',
    'contact.Naturvardsverket.en': 'Swedish Environmental Protection Agency',
    'contact.Naturvardsverket.no': 'Naturvårdsverket',
    'contact.Naturvardsverket.sv': 'Naturvårdsverket',
    'contact.atMDIR.en': 'Norwegian Environment Agency',
    'contact.atMDIR.no': 'hos Miljødirektoratet',
    'contact.atMDIR.sv': 'hos Miljødirektoratet',
    'contact.atNaturvardsverket.en': 'Swedish Environmental Protection Agency',
    'contact.atNaturvardsverket.no': 'hos Naturvårdsverket',
    'contact.atNaturvardsverket.sv': 'Naturvårdsverkets webbplats',
    'country.norway.en': 'Norway',
    'country.norway.no': 'Norge',
    'country.norway.sv': 'Norge',
    'country.sweden.en': 'Sweden',
    'country.sweden.no': 'Sverige',
    'country.sweden.sv': 'Sverige',
    'dateLabel.from.en': 'From',
    'dateLabel.from.no': 'Fra',
    'dateLabel.from.sv': 'Från',
    'dateLabel.lastMonth.en': 'Last month',
    'dateLabel.lastMonth.no': 'Siste måned',
    'dateLabel.lastMonth.sv': 'Senaste månaden',
    'dateLabel.lastWeek.en': 'Last week',
    'dateLabel.lastWeek.no': 'Siste uke',
    'dateLabel.lastWeek.sv': 'Senaste veckan',
    'dateLabel.lastYear.en': 'Last 12 months',
    'dateLabel.lastYear.no': 'Siste år',
    'dateLabel.lastYear.sv': 'Senaste året',
    'dateLabel.to.en': 'To',
    'dateLabel.to.no': 'Til',
    'dateLabel.to.sv': 'Till',
    'dateLabel.today.en': 'Today',
    'dateLabel.today.no': 'I dag',
    'dateLabel.today.sv': 'Idag',
    'dateLabel.chooseDate.en': 'Select date',
    'dateLabel.chooseDate.no': 'Velg dato',
    'dateLabel.chooseDate.sv': 'Välj datum',
    'dateLabel.invalidFromDate.en': 'Date should not be after to-date',
    'dateLabel.invalidFromDate.no': 'Dato må ikke være etter til-dato',
    'dateLabel.invalidFromDate.sv': 'Datum får inte vara efter till-datum',
    'dateLabel.invalidToDate.en': 'Date should not be before from date',
    'dateLabel.invalidToDate.no': 'Dato må ikke være før fra-dato',
    'dateLabel.invalidToDate.sv': 'Datum får inte vara före från-datum',
    'dateLabel.invalidDate.en': 'Invalid Date Format',
    'dateLabel.invalidDate.no': 'Ugyldig datoformat',
    'dateLabel.invalidDate.sv': 'Ogiltigt datumformat',
    'datePicker.time.en': 'Time',
    'datePicker.time.no': 'Tid',
    'datePicker.time.sv': 'Tid',
    'deadCarnivore.ageByNINA.en': 'determined by NINA',
    'deadCarnivore.ageByNINA.no': 'fastsatt av NINA',
    'deadCarnivore.ageByNINA.sv': 'fastställt av NINA',
    'deadCarnivore.ageBySVA.en': 'determined by SVA',
    'deadCarnivore.ageBySVA.no': 'fastsatt av SVA',
    'deadCarnivore.ageBySVA.sv': 'fastställt av Statens veterinärmedicinska anstalt',
    'deadCarnivore.agePreliminary.en': 'preliminary assessment',
    'deadCarnivore.agePreliminary.no': 'foreløpig vurdering',
    'deadCarnivore.agePreliminary.sv': 'preliminär bedömning',
    'deadCarnivore.controlType.fieldVerificationCarnivoreContact.en':
        'The death has been inspected by carnivore contact personnel (Norway)',
    'deadCarnivore.controlType.fieldVerificationCarnivoreContact.no': 'Dødsfallet er kontrollert av rovviltkontakt',
    'deadCarnivore.controlType.fieldVerificationCarnivoreContact.sv': 'Händelsen är kontrollerad av rovviltkontakt (Norge)',
    'deadCarnivore.controlType.fieldVerificationSNO.en':
        'The death has been inspected by the Norwegian Nature Inspectorate (Norway)',
    'deadCarnivore.controlType.fieldVerificationSNO.no': 'Dødsfallet er kontrollert av Statens Naturoppsyn',
    'deadCarnivore.controlType.fieldVerificationSNO.sv': 'Händelsen är kontrollerad av Statens Naturoppsyn (Norge)',
    'deadCarnivore.controlType.photograph.en': 'The death has been inspected via photo',
    'deadCarnivore.controlType.photograph.no': 'Dødsfallet er kontrollert ved hjelp av foto',
    'deadCarnivore.controlType.photograph.sv': 'Händelsen är kontrollerad med hjälp av foto',
    'deadCarnivore.controlType.notVerified.en': 'The death has not been inspected',
    'deadCarnivore.controlType.notVerified.no': 'Dødsfallet er ikke kontrollert',
    'deadCarnivore.controlType.notVerified.sv': 'Händelsen är inte kontrollerad',
    'deadCarnivore.controlType.interview.en': 'The death has been inspected via interview or letter',
    'deadCarnivore.controlType.interview.no': 'Dødsfallet er kontrollert ved hjelp av intervju eller brev',
    'deadCarnivore.controlType.interview.sv': 'Händelsen är kontrollerad via intervju eller brev',
    'deadCarnivore.controlType.laboratory.en': 'The death has been inspected in a laboratory',
    'deadCarnivore.controlType.laboratory.no': 'Dødsfallet er kontrollert i laboratorium',
    'deadCarnivore.controlType.laboratory.sv': 'Djuret är kontrollerat av laboratorium',
    'deadCarnivore.controlType.fieldVerificationLST.en':
        'The death has been inspected by the County Administrative Board (Sweden)',
    'deadCarnivore.controlType.fieldVerificationLST.no': 'Dødsfallet er kontrollert av Länsstyrelsen',
    'deadCarnivore.controlType.fieldVerificationLST.sv': 'Händelsen är kontrollerad av Länsstyrelsen',
    'deadCarnivore.controlType.fieldVerificationSamiVillage.en':
        'The death has been inspected by a Saami village (Sweden)',
    'deadCarnivore.controlType.fieldVerificationSamiVillage.no': 'Dødsfallet er kontrollert av sameby',
    'deadCarnivore.controlType.fieldVerificationSamiVillage.sv':
        'Händelsen är kontrollerad av samebyns inventeringssamordnare',
    'deadCarnivore.controlType.GPS.en': 'The death has been inspected via GPS- or radio tracking',
    'deadCarnivore.controlType.GPS.no': 'Dødsfallet er kontrollert ved hjelp av GPS eller radiopeiling',
    'deadCarnivore.controlType.GPS.sv': 'Händelsen är kontrollerad med hjälp av gps eller radiopejling',
    'deadCarnivore.dead.en': 'Dead',
    'deadCarnivore.dead.no': 'Død',
    'deadCarnivore.dead.sv': 'Död',
    'deadCarnivore.dead.intetkjonn.en': 'Dead',
    'deadCarnivore.dead.intetkjonn.no': 'Død',
    'deadCarnivore.dead.intetkjonn.sv': 'Dött',
    'deadCarnivore.deathCausePhrase.killed.licenseHunting.en': 'was killed during legal hunting',
    'deadCarnivore.deathCausePhrase.killed.licenseHunting.no': 'ble felt ved lisensfelling',
    'deadCarnivore.deathCausePhrase.killed.licenseHunting.sv': 'fälldes under licensjakt',
    'deadCarnivore.deathCausePhrase.shotDamageConfirmed.licenseHunting.en': 'was shot at during legal hunting, damage confirmed but not killed',
    'deadCarnivore.deathCausePhrase.shotDamageConfirmed.licenseHunting.no': 'ble påskutt i lisensjakt. Skadet, men ikke felt',
    'deadCarnivore.deathCausePhrase.shotDamageConfirmed.licenseHunting.sv': 'påskjuten vid licensjakt, konstaterades skadad men fälldes inte',
    'deadCarnivore.deathCausePhrase.killed.quotaHunting.en': 'was killed during legal hunting',
    'deadCarnivore.deathCausePhrase.killed.quotaHunting.no': 'ble felt i kvotejakt',
    'deadCarnivore.deathCausePhrase.killed.quotaHunting.sv': 'fälldes under licensjakt/kvotjakt',
    'deadCarnivore.deathCausePhrase.shotDamageConfirmed.quotaHunting.en': 'was shot at during legal hunting, damage confirmed but not killed',
    'deadCarnivore.deathCausePhrase.shotDamageConfirmed.quotaHunting.no': 'ble påskutt i kvotejakt. Skadet, men ikke felt',
    'deadCarnivore.deathCausePhrase.shotDamageConfirmed.quotaHunting.sv': 'påskjuten vid licensjakt, konstaterades skadad men fälldes inte',
    'deadCarnivore.deathCausePhrase.intetkjonn.shotDamageConfirmed.quotaHunting.en': 'was shot at during legal hunting, damage confirmed but not killed',
    'deadCarnivore.deathCausePhrase.intetkjonn.shotDamageConfirmed.quotaHunting.no': 'ble påskutt i kvotejakt. Skadet, men ikke felt',
    'deadCarnivore.deathCausePhrase.intetkjonn.shotDamageConfirmed.quotaHunting.sv': 'påskjutet vid licensjakt/kvotjakt, konstaterades skadat men fälldes inte',
    'deadCarnivore.deathCausePhrase.killed.damageControl.en': 'was killed on a damage control permit',
    'deadCarnivore.deathCausePhrase.killed.damageControl.no': 'ble felt i skadefelling',
    'deadCarnivore.deathCausePhrase.killed.damageControl.sv': 'fälldes under skyddsjakt',
    'deadCarnivore.deathCausePhrase.shotDamageConfirmed.damageControl.en': 'was shot at on a damage control permit, damage confirmed but not killed',
    'deadCarnivore.deathCausePhrase.shotDamageConfirmed.damageControl.no': 'ble påskutt i skadefelling. Skadet, men ikke felt',
    'deadCarnivore.deathCausePhrase.shotDamageConfirmed.damageControl.sv': 'påskjuten vid skyddsjakt, konstaterades skadad men fälldes inte',
    'deadCarnivore.deathCausePhrase.intetkjonn.shotDamageConfirmed.damageControl.en': 'was shot at on a damage control permit',
    'deadCarnivore.deathCausePhrase.intetkjonn.shotDamageConfirmed.damageControl.no': 'ble påskutt i skadefelling. Skadet, men ikke felt',
    'deadCarnivore.deathCausePhrase.intetkjonn.shotDamageConfirmed.damageControl.sv': 'påskjutet vid skyddsjakt, konstaterades skadat men fälldes inte',
    'deadCarnivore.deathCausePhrase.killed.defenceOfAnimal.en': 'was killed on a damage control permit (enskilds initiativ (S))',
    'deadCarnivore.deathCausePhrase.killed.defenceOfAnimal.no': 'ble felt i nødverge av hensyn til tamdyr',
    'deadCarnivore.deathCausePhrase.killed.defenceOfAnimal.sv': 'fälldes under skyddsjakt enskilds initiativ',
    'deadCarnivore.deathCausePhrase.shotDamageConfirmed.defenceOfAnimal.en': 'was shot at on a damage control permit (enskilds initiativ (S)), damage confirmed but not killed',
    'deadCarnivore.deathCausePhrase.shotDamageConfirmed.defenceOfAnimal.no': 'ble påskutt i nødverge av hensyn til tamdyr. Skadet, men ikke felt',
    'deadCarnivore.deathCausePhrase.shotDamageConfirmed.defenceOfAnimal.sv': 'påskjuten vid skyddsjakt på enskilds initiativ, konstaterades skadad men fälldes inte',
    'deadCarnivore.deathCausePhrase.intetkjonn.shotDamageConfirmed.defenceOfAnimal.en': 'was shot at on a damage control permit (enskilds initiativ (S)), damage confirmed but not killed',
    'deadCarnivore.deathCausePhrase.intetkjonn.shotDamageConfirmed.defenceOfAnimal.no': 'ble påskutt i nødverge av hensyn til tamdyr. Skadet, men ikke felt',
    'deadCarnivore.deathCausePhrase.intetkjonn.shotDamageConfirmed.defenceOfAnimal.sv': 'påskjutet vid skyddsjakt på enskilds initiativ, konstaterades skadat men fälldes inte',
    'deadCarnivore.deathCausePhrase.killed.snoMission.en': 'was killed by Statens Naturoppsyn (Norway)',
    'deadCarnivore.deathCausePhrase.killed.snoMission.no': 'ble felt av Statens Naturoppsyn',
    'deadCarnivore.deathCausePhrase.killed.snoMission.sv': 'fälldes av Statens Naturoppsyn (Norge)',
    'deadCarnivore.deathCausePhrase.shotDamageConfirmed.snoMission.en': 'was shot by Statens Naturoppsyn (Norway), damage confirmed but not killed',
    'deadCarnivore.deathCausePhrase.shotDamageConfirmed.snoMission.no': 'ble påskutt i fellingsoppdrag til Statens Naturoppsyn. Skadet, men ikke felt',
    'deadCarnivore.deathCausePhrase.shotDamageConfirmed.snoMission.sv': 'påskjuten under uppdrag till Statens Naturoppsyn (Norge), konstaterades skadad men fälldes inte',
    'deadCarnivore.deathCausePhrase.intetkjonn.shotDamageConfirmed.snoMission.en': 'was shot by Statens Naturoppsyn (Norway), damage confirmed but not killed',
    'deadCarnivore.deathCausePhrase.intetkjonn.shotDamageConfirmed.snoMission.no': 'påskutt i fellingsoppdrag av Statens Naturoppsyn. Skadet, men ikke felt',
    'deadCarnivore.deathCausePhrase.intetkjonn.shotDamageConfirmed.snoMission.sv': 'påskjutet under uppdrag till Statens Naturoppsyn (Norge), konstaterades skadat men fälldes inte',
    'deadCarnivore.deathCausePhrase.animalWelfare.en': 'was euthanised for animal welfare reasons',
    'deadCarnivore.deathCausePhrase.animalWelfare.no': 'ble felt av dyrevelferdshensyn',
    'deadCarnivore.deathCausePhrase.animalWelfare.sv': 'avlivades av djurskyddsskäl',
    'deadCarnivore.deathCausePhrase.policeDecision.en': 'was killed by decision of the police',
    'deadCarnivore.deathCausePhrase.policeDecision.no': 'ble felt etter beslutning fra politiet',
    'deadCarnivore.deathCausePhrase.policeDecision.sv': 'avlivades på Polismyndighetens initiativ',
    'deadCarnivore.deathCausePhrase.killedByAnimal.en': 'was killed by another animal',
    'deadCarnivore.deathCausePhrase.killedByAnimal.no': 'ble drept av annet dyr',
    'deadCarnivore.deathCausePhrase.killedByAnimal.sv': 'dödades av annat djur',
    'deadCarnivore.deathCausePhrase.research.en': 'died in connection with research',
    'deadCarnivore.deathCausePhrase.research.no': 'døde i forbindelse med forskning',
    'deadCarnivore.deathCausePhrase.research.sv': 'dog i samband med forskningsverksamhet',
    'deadCarnivore.deathCausePhrase.illegalKilling.en': 'was illegally killed',
    'deadCarnivore.deathCausePhrase.illegalKilling.no': 'er illegalt avlivet',
    'deadCarnivore.deathCausePhrase.illegalKilling.sv': 'dödades illegalt',
    'deadCarnivore.deathCausePhrase.collisionRail.en': 'was hit by a train',
    'deadCarnivore.deathCausePhrase.collisionRail.no': 'ble påkjørt av tog',
    'deadCarnivore.deathCausePhrase.collisionRail.sv': 'blev påkörd av tåg',
    'deadCarnivore.deathCausePhrase.collisionRoad.en': 'was hit by a car',
    'deadCarnivore.deathCausePhrase.collisionRoad.no': 'ble påkjørt av bil',
    'deadCarnivore.deathCausePhrase.collisionRoad.sv': 'blev påkörd av bil',
    'deadCarnivore.deathCausePhrase.killed.selfDefence.en': 'was killed in defense of person, animal or other property',
    'deadCarnivore.deathCausePhrase.killed.selfDefence.no': 'ble felt i nødverge',
    'deadCarnivore.deathCausePhrase.killed.selfDefence.sv': 'dödades i nödvärn',
    'deadCarnivore.deathCausePhrase.shotDamageConfirmed.selfDefence.en': 'was shot at in defense of person, animal or other property. Damage confirmed but not killed',
    'deadCarnivore.deathCausePhrase.shotDamageConfirmed.selfDefence.no': 'ble påskutt i nødverge. Skadet, men ikke felt',
    'deadCarnivore.deathCausePhrase.shotDamageConfirmed.selfDefence.sv': 'påskjuten i nödvärn, konstaterades skadad men fälldes inte',
    'deadCarnivore.deathCausePhrase.intetkjonn.shotDamageConfirmed.selfDefence.en': 'was shot at in defense of person, animal or other property. Damage confirmed but not killed',
    'deadCarnivore.deathCausePhrase.intetkjonn.shotDamageConfirmed.selfDefence.no': 'ble påskutt i nødverge. Skadet, men ikke felt',
    'deadCarnivore.deathCausePhrase.intetkjonn.shotDamageConfirmed.selfDefence.sv': 'påskjutet i nödvärn, konstaterades skadat men fälldes inte',
    'deadCarnivore.deathCausePhrase.accident.en': 'died in an accident',
    'deadCarnivore.deathCausePhrase.accident.no': 'døde i en ulykke',
    'deadCarnivore.deathCausePhrase.accident.sv': 'dödades i olyckshändelse',
    'deadCarnivore.deathCausePhrase.disease.en': 'died from disease',
    'deadCarnivore.deathCausePhrase.disease.no': 'døde av sykdom',
    'deadCarnivore.deathCausePhrase.disease.sv': 'dog av sjukdom',
    'deadCarnivore.deathCausePhrase.unknown.en': 'died from unknown causes',
    'deadCarnivore.deathCausePhrase.unknown.no': 'døde av ukjente årsaker',
    'deadCarnivore.deathCausePhrase.unknown.sv': 'dog av okänd orsak',
    'deadCarnivore.deathCausePhrase.intetkjonn.collisionRail.en': 'was hit by a train',
    'deadCarnivore.deathCausePhrase.intetkjonn.collisionRail.no': 'ble påkjørt av tog',
    'deadCarnivore.deathCausePhrase.intetkjonn.collisionRail.sv': 'blev påkört av tåg',
    'deadCarnivore.deathCausePhrase.intetkjonn.collisionRoad.en': 'was hit by a car',
    'deadCarnivore.deathCausePhrase.intetkjonn.collisionRoad.no': 'ble påkjørt av bil',
    'deadCarnivore.deathCausePhrase.intetkjonn.collisionRoad.sv': 'blev påkört av bil',
    'deadCarnivore.slaughterWeight.en': 'carcass weight',
    'deadCarnivore.slaughterWeight.no': 'slaktevekt',
    'deadCarnivore.slaughterWeight.sv': 'slaktvikt',
    'deadCarnivore.unknown.en': 'Unknown',
    'deadCarnivore.unknown.no': 'Ukjent',
    'deadCarnivore.unknown.sv': 'Okänd',
    'deadCarnivore.documentation.en': 'The death is ',
    'deadCarnivore.documentation.no': 'Dødsfallet er ',
    'deadCarnivore.documentation.sv': 'Händelsen är ',
    'deadCarnivore.year.en': 'year',
    'deadCarnivore.year.no': 'år',
    'deadCarnivore.year.sv': 'år',
    'deadCarnivore.gender.male.en': 'Male',
    'deadCarnivore.gender.male.no': 'Hann',
    'deadCarnivore.gender.male.sv': 'Hane',
    'deadCarnivore.gender.female.en': 'Female',
    'deadCarnivore.gender.female.no': 'Hunn',
    'deadCarnivore.gender.female.sv': 'Hona',
    'deadCarnivore.gender.unknown.en': 'Unknown',
    'deadCarnivore.gender.unknown.no': 'Ukjent',
    'deadCarnivore.gender.unknown.sv': 'Okänt',
    'rovviltskade.report.description.en': 'These statistics show the number of domestic animals and items damaged by carnivores in Sweden. Damages on reindeer is not included. The results are filtered by year, county, carnivore and type of damage. For further details, please click on "About the data".',
    'rovviltskade.report.description.no':'Statistikk over skader påført av rovdyr',
    'rovviltskade.report.description.sv':'Här visas statistik om angrepp av stora rovdjur på tamdjur med mera i Sverige. Skador på ren är inte inkluderat. Antalen som visas beror på de filtreringar du gör på år, län, rovdjur och vad som skadats. Tryck på "Om statistiken" för att läsa mer.',
    'deadCarnivore.report.description.en': 'These statistics provide the number of carnivores that have been killed through hunting, damage control, or found deceased in Scandinavia. The results are filtered by country, counties, years, and causes of death. For further details, please click on "About the data".',
    'deadCarnivore.report.description.no': 'Her vises antall rovdyr som er felt ved jakt, lisensfelling, skadefelling eller er funnet døde av andre årsaker i Skandinavia. Resultatet er basert på de filtreringer du gjør på land, fylker, år og dødsårsaker. Klikk på ”Om statistikken” for å lese mer.',
    'deadCarnivore.report.description.sv': 'Här visas antal rovdjur som fällts vid jakt eller hittats döda av annan orsak i Skandinavien. Antalen som visas beror på de filtreringar du gör på år, land, län och dödsorsak. Tryck på "Om statistiken" för att läsa mer.',
    'dnaInfo.barcode.no': 'Strekkoden for DNA-prøven er ',
    'dnaInfo.barcode.sv': 'Streckkoden för DNA-provet är ',
    'dnaInfo.barcode.en': 'The DNA-sample barcode is ',
    'dnaInfo.identity.no': 'Den er identifisert som ',
    'dnaInfo.identity.sv': 'Den är identifierad som ',
    'dnaInfo.identity.en': 'It is identified as ',
    'dnaInfo.unanalysed.en': 'The sample has not been analysed.',
    'dnaInfo.unanalysed.no': 'Prøven er ikke analysert.',
    'dnaInfo.unanalysed.sv': 'Provet är inte analyserat.',
    'error.oida.en': 'Something went wrong',
    'error.oida.no': 'Oi da.. noe gikk galt',
    'error.oida.sv': 'Oj, ett fel har uppstått',
    'error.goBack.en': 'Go back to the homepage',
    'error.goBack.no': 'Tilbake til startsiden',
    'error.goBack.sv': 'Tillbaka till startsidan',
    'evaluation.documented.en': 'Confirmed',
    'evaluation.documented.no': 'Dokumentert',
    'evaluation.documented.sv': 'Dokumenterad',
    'evaluation.assessedSure.en': 'Presumed correct',
    'evaluation.assessedSure.no': 'Antatt sikker',
    'evaluation.assessedSure.sv': 'Bedömd som säker',
    'evaluation.unsure.en': 'Uncertain',
    'evaluation.unsure.no': 'Usikker',
    'evaluation.unsure.sv': 'Osäker',
    'evaluation.erroneousReport.en': 'Show erronous reports, i.e. tracks that were not from carnivores',
    'evaluation.erroneousReport.no': 'Vis feilmeld. - dvs spor som ikke var rovdyr likevel',
    'evaluation.erroneousReport.sv': 'Visa felrapporter',
    'evaluation.title.documented.en': 'Confirmed',
    'evaluation.title.documented.no': 'Dokumentert',
    'evaluation.title.documented.sv': 'Dokumenterad',
    'evaluation.title.assessedSure.en': 'Presumed correct',
    'evaluation.title.assessedSure.no': 'Antatt sikker',
    'evaluation.title.assessedSure.sv': 'Bedömd som säker',
    'evaluation.title.unsure.en': 'Uncertain',
    'evaluation.title.unsure.no': 'Usikker',
    'evaluation.title.unsure.sv': 'Osäker',
    'evaluation.title.erroneousReport.en': 'Erroneous reports',
    'evaluation.title.erroneousReport.no': 'Feilmeldinger',
    'evaluation.title.erroneousReport.sv': 'Felrapporter',
    'evaluation.title.noneChecked.en': 'None selected',
    'evaluation.title.noneChecked.no': 'Ingen valgt',
    'evaluation.title.noneChecked.sv': 'Ingen vald',
    'evaluation.label.documented.en': 'Confirmed',
    'evaluation.label.documented.no': 'Dokumentert',
    'evaluation.label.documented.sv': 'Dokumenterad',
    'evaluation.label.assessedSure.en': 'Presumed correct',
    'evaluation.label.assessedSure.no': 'Antatt sikker',
    'evaluation.label.assessedSure.sv': 'Bedömd som säker',
    'evaluation.label.unsure.en': 'Uncertain',
    'evaluation.label.unsure.no': 'Usikker',
    'evaluation.label.unsure.sv': 'Osäker',
    'evaluation.label.erroneousReport.en': 'Erroneous report on/about',
    'evaluation.label.erroneousReport.no': 'Feilmelding om ',
    'evaluation.label.erroneousReport.sv': 'Felrapport om',
    'expandableList.locationInputPlaceholder.en': 'Country, region, county or municipality',
    'expandableList.locationInputPlaceholder.no': 'Land, region, fylke eller kommune',
    'expandableList.locationInputPlaceholder.sv': 'Land, län, kommun eller förvaltningsområde',
    'expandableList.periodFromInputPlaceholder.en': 'From date',
    'expandableList.periodFromInputPlaceholder.no': 'Fra dato',
    'expandableList.periodFromInputPlaceholder.sv': 'Från datum',
    'expandableList.periodToInputPlaceholder.en': 'To date',
    'expandableList.periodToInputPlaceholder.no': 'Til dato',
    'expandableList.periodToInputPlaceholder.sv': 'Till datum',
    'expandableList.showMore.en': 'Show more options',
    'expandableList.showMore.no': 'Vis flere valg',
    'expandableList.showMore.sv': 'Visa fler val',
    'expandableList.showLess.en': 'Show less options',
    'expandableList.showLess.no': 'Vis færre valg ',
    'expandableList.showLess.sv': 'Visa färre val',
    'filterbar.carnivoreDamage.en': 'Carnivore damages',
    'filterbar.carnivoreDamage.no': 'Skader etter rovdyr',
    'filterbar.carnivoreDamage.sv': 'Skada orsakad av rovdjur',
    'filterbar.date.en': 'Date',
    'filterbar.date.no': 'Dato',
    'filterbar.date.sv': 'Datum',
    'filterbar.evaluation.en': 'Evaluation',
    'filterbar.evaluation.no': 'Vurdering av registrering',
    'filterbar.evaluation.sv': 'Bedömning',
    'filterbar.observation.en': 'Observation of carnivore',
    'filterbar.observation.no': 'Observasjon av rovdyr',
    'filterbar.observation.sv': 'Observation av rovdjur',
    'filterbar.otherCarnivore.en': 'Damage caused by other carnivore',
    'filterbar.otherCarnivore.no': 'Skader påført av andre rovdyr',
    'filterbar.otherCarnivore.sv': 'Skador orsakade av andra rovdjur',
    'filterbar.place.en': 'Place',
    'filterbar.place.no': 'Sted',
    'filterbar.place.sv': 'Plats',
    'filterbar.showMoreSpecies.en': 'Show more species',
    'filterbar.showMoreSpecies.no': 'Vis flere arter',
    'filterbar.showMoreSpecies.sv': 'Visa fler arter',
    'filterbar.species.en': 'Species',
    'filterbar.species.no': 'Arter',
    'filterbar.species.sv': 'Arter',
    'filterbar.showAll.en': 'Select all',
    'filterbar.showAll.no': 'Velg alle',
    'filterbar.showAll.sv': 'Välj alla',
    'filterbar.documented.en': 'Confirmed, presumed correct and uncertain',
    'filterbar.documented.no': 'Dokumentert, sikker og usikker',
    'filterbar.documented.sv': 'Dokumenterad, bedömd som säker och osäker',
    'filterbar.showFilter.en': 'Show filters',
    'filterbar.showFilter.no': 'Vis filter',
    'filterbar.showFilter.sv': 'Visa filter',
    'filterbar.HideFilter.en': 'Hide filters',
    'filterbar.HideFilter.no': 'Skjul filter',
    'filterbar.HideFilter.sv': 'Göm filter',
    'filterbar.showMoreFilter.en': 'Show more filters',
    'filterbar.showMoreFilter.no': 'Vis flere filter',
    'filterbar.showMoreFilter.sv': 'Visa fler filter',
    'filterlist.goldenEagle.en': 'Damages caused by golden eagle',
    'filterlist.goldenEagle.no': 'Skader etter kongeørn',
    'filterlist.goldenEagle.sv': 'Skada orsakad av kungsörn',
    'filterlist.unknownProtected.en': 'Damages caused by unknown carnivore',
    'filterlist.unknownProtected.no': 'Skader etter ukjent fredet rovvilt',
    'filterlist.unknownProtected.sv': 'Skada orsakat av okänt fredat rovdjur',
    'footer.developedBy.en': 'Developed by ',
    'footer.developedBy.no': 'Utviklet av ',
    'footer.developedBy.sv': 'Utvecklad av ',
    'incidentArea.backCountry.en': 'Back country',
    'incidentArea.backCountry.no': 'Utmark',
    'incidentArea.backCountry.sv': 'Bete utan stängsel',
    'incidentArea.cultivatedLand.en': 'Cultivated land',
    'incidentArea.cultivatedLand.no': 'Innmark',
    'incidentArea.cultivatedLand.sv': 'Inäga',
    'incidentArea.fields.en': 'Fields',
    'incidentArea.fields.no': 'Inngjerdet innmark',
    'incidentArea.fields.sv': 'Bete innanför stängsel',
    'incidentArea.fence.en': 'Fields with predator fence',
    'incidentArea.fence.no': 'Område med rovviltavvisende gjerde',
    'incidentArea.fence.sv': 'Bete med rovdjursavvisande stängsel',
    'individual.origin.f1.en': 'The carnivore is a first generation offspring (F1:a) of an immigrated Finnish-Russian wolf',
    'individual.origin.f1.no': 'Dyret er et første generasjons avkom (F1:a) av en innvandret finsk-russisk ulv',
    'individual.origin.f1.sv': 'Djuret är en första generationens avkomma (F1:a) till en invandrad finsk-rysk varg',
    'individual.origin.hybrid.en': 'The carnivore is a hybrid of wolf and dog',
    'individual.origin.hybrid.no': 'Dyret er en hybrid mellom ulv og hund',
    'individual.origin.hybrid.sv': 'Djuret är en hybrid mellan varg och hund',
    'individual.origin.immigrant.en': 'The carnivore is an imigrated Finnish-Russian wolf',
    'individual.origin.immigrant.no': 'Dyret er en innvandret finsk-russisk ulv',
    'individual.origin.immigrant.sv': 'Djuret är en invandrad finsk-rysk varg',
    'languageSelect.language.en': 'Language',
    'languageSelect.language.no': 'Språk',
    'languageSelect.language.sv': 'Språk',
    'map.layers.en': 'Layers',
    'map.layers.no': 'Kartlag',
    'map.layers.sv': 'Kartskikt',
    'map.zoominn.en': 'Zoom in',
    'map.zoominn.no': 'Zoom inn',
    'map.zoominn.sv': 'Zooma in',
    'map.zoomut.en': 'Zoom out',
    'map.zoomut.no': 'Zoom ut',
    'map.zoomut.sv': 'Zooma ut',
    'menu.en': 'Menu',
    'menu.no': 'Meny',
    'menu.sv': 'Meny',
    'menu.about.en': 'About Rovbase',
    'menu.about.no': 'Om Rovbase',
    'menu.about.sv': 'Om Rovbase',
    'menu.contact.en': 'Contact us',
    'menu.contact.no': 'Kontakt oss',
    'menu.contact.sv': 'Kontakta oss',
    'menu.email.en': 'E-mail',
    'menu.email.no': 'E-post',
    'menu.email.sv': 'E-post',
    'menu.facts.en': 'Carnivore facts',
    'menu.facts.no': 'Rovdyrfakta',
    'menu.facts.sv': 'Fakta om stora rovdjur',
    'menu.phone.en': 'Tel',
    'menu.phone.no': 'Tlf',
    'menu.phone.sv': 'Tel',
    'menu.privacypolicy.en': 'Privacy policy',
    'menu.privacypolicy.no': 'Personvern',
    'menu.privacypolicy.sv': 'Personuppgifter',
    'menu.refund.en': 'Compensation sheep and reindeer',
    'menu.refund.no': 'Erstatning sau og rein',
    'menu.refund.sv': 'Ersättning får och ren',
    'menu.refundSheep.en': 'Compensation sheep',
    'menu.refundSheep.no': 'Erstatning sau',
    'menu.refundSheep.sv': 'Ersättning får (Norge)',
    'menu.refundReindeer.en': 'Compensation reindeer',
    'menu.refundReindeer.no': 'Erstatning rein',
    'menu.refundReindeer.sv': 'Ersättning ren (Norge)',
    'menu.reportDodeRovdyr.no': 'Statistikk døde rovdyr',
    'menu.reportDodeRovdyr.sv': 'Statistik döda rovdjur',
    'menu.reportDodeRovdyr.en': 'Statistics dead carnivores',
    'menu.reportRovviltskade.no': 'Statistikk rovviltskader (Sverige)',
    'menu.reportRovviltskade.sv': 'Viltskadestatistik (Sverige)',
    'menu.reportRovviltskade.en': 'Statistics carnivore damanges',
    'menu.search.en': 'Search',
    'menu.search.no': 'Søk i Rovbase',
    'menu.search.sv': 'Sök i Rovbase',
    'menu.privacyPolicy.en': 'Privacy Policy',
    'menu.privacyPolicy.no': 'Personvernerklæring',
    'menu.privacyPolicy.sv': 'Integritetspolicy',
    'menu.accessibilityStatement.en': 'Accessibility Statement',
    'menu.accessibilityStatement.no': 'Tilgjengelighetserklæring',
    'menu.accessibilityStatement.sv': 'Tillgänglighet',
    'footer.organisationNumber.en': 'Organisation number',
    'footer.organisationNumber.no': 'Organisasjonsnummer',
    'footer.organisationNumber.sv': 'Organisationsnummer',
    'footer.email.en': 'E-mail',
    'footer.email.no': 'E-post',
    'footer.email.sv': 'E-post',
    'footer.phone.en': 'Phone',
    'footer.phone.no': 'Telefon',
    'footer.phone.sv': 'Telefon',
    'number.one.en': 'A',
    'number.one.no': 'En',
    'number.one.sv': 'En',
    'observations.en': 'Observations',
    'observations.no': 'Observasjoner',
    'observations.sv': 'Observationer',
    'observations.age.en': 'Age',
    'observations.age.no': 'Alder',
    'observations.age.sv': 'Ålder',
    'observations.analysedDna.en': 'Analysed DNA',
    'observations.analysedDna.no': 'Analysert DNA',
    'observations.analysedDna.sv': 'Analyserat DNA',
    'observations.bed.en': 'Bed',
    'observations.bed.no': 'Leie',
    'observations.bed.sv': 'Lega',
    'observations.blood.en': 'Blood',
    'observations.blood.no': 'Blod',
    'observations.blood.sv': 'Blod',
    'observations.dead.en': 'Dead',
    'observations.dead.no': 'Død',
    'observations.dead.sv': 'Död',
    'observations.deadCarnivores.en': 'Dead carnivores',
    'observations.deadCarnivores.no': 'Døde rovdyr',
    'observations.deadCarnivores.sv': 'Döda rovdjur',
    'observations.digging.en': 'Digging',
    'observations.digging.no': 'Graving',
    'observations.digging.sv': 'Grävt',
    'observations.dna.en': 'Dna',
    'observations.dna.no': 'Dna',
    'observations.dna.sv': 'Dna',
    'observations.faeces.en': 'Feaces',
    'observations.faeces.no': 'Ekskrement',
    'observations.faeces.sv': 'Spillning',
    'observations.footprint.en': 'Footprint',
    'observations.footprint.no': 'Spor',
    'observations.footprint.sv': 'Spår',
    'observations.hair.en': 'Hair',
    'observations.hair.no': 'Hår',
    'observations.hair.sv': 'Hår',
    'observations.hairOrFeather.en': 'Hair',
    'observations.hairOrFeather.no': 'Hår',
    'observations.hairOrFeather.sv': 'Hår',
    'observations.observation.en': 'Observation',
    'observations.observation.no': 'Observasjon',
    'observations.observation.sv': 'Observation',
    'observations.offspring.en': 'Offspring',
    'observations.offspring.no': 'Yngling',
    'observations.offspring.sv': 'Föryngring',
    'observations.reproduction.en': 'Offspring',
    'observations.reproduction.no': 'Yngling',
    'observations.reproduction.sv': 'Föryngring',
    'observations.territorialMarking.en': 'Territorial marking',
    'observations.territorialMarking.no': 'Revirmarkering',
    'observations.territorialMarking.sv': 'Revirmarkering',
    'observations.tissue.en': 'Tissue',
    'observations.tissue.no': 'Vev',
    'observations.tissue.sv': 'Vävnad',
    'observations.treeMarkings.en': 'Tree markings',
    'observations.treeMarkings.no': 'Merker på trær',
    'observations.treeMarkings.sv': 'Märken på träd',
    'observations.unanalysedDna.en': 'Unanalysed DNA',
    'observations.unanalysedDna.no': 'Uanalysert DNA',
    'observations.unanalysedDna.sv': 'Ej analyserat DNA-prov',
    'observations.unspecifiedType.en': 'Unspecified type',
    'observations.unspecifiedType.no': 'Uklassifisert',
    'observations.unspecifiedType.sv': 'Observationstyp ej angiven',
    'observations.visualObservation.en': 'Visual observation',
    'observations.visualObservation.no': 'Synsobservasjon',
    'observations.visualObservation.sv': 'Synobservation',
    'observations.visualObservations.en': 'Visual observation',
    'observations.visualObservations.no': 'Synsobserv.',
    'observations.visualObservations.sv': 'Synobservation',
    'observationsFilterList.analysedDna.en': 'Analysed DNA',
    'observationsFilterList.analysedDna.no': 'Analysert DNA-prøve',
    'observationsFilterList.analysedDna.sv': 'Analyserat DNA-prov',
    'observationsFilterList.deadCarnivores.en': 'Dead carnivores',
    'observationsFilterList.deadCarnivores.no': 'Dødt rovdyr observert',
    'observationsFilterList.deadCarnivores.sv': 'Dött rovdjur observerat',
    'observationsFilterList.footprint.en': 'Traces observed',
    'observationsFilterList.footprint.no': 'Spor observert',
    'observationsFilterList.footprint.sv': 'Spår/tassavtryck',
    'observationsFilterList.offspring.en': 'Offspring',
    'observationsFilterList.offspring.no': 'Yngling',
    'observationsFilterList.offspring.sv': 'Föryngring',
    'observationsFilterList.unanalysedDna.en': 'Unanalysed DNA',
    'observationsFilterList.unanalysedDna.no': 'Uanalysert DNA-prøve',
    'observationsFilterList.unanalysedDna.sv': 'Ej analyserat DNA-prov',
    'observationsFilterList.visualObservation.en': 'Carnivore observed',
    'observationsFilterList.visualObservation.no': 'Dyr observert',
    'observationsFilterList.visualObservation.sv': 'Rovdjur observerat',
    'observations.controlType.fieldVerificationCarnivoreContact.en':
        'The observation has been inspected by carnivore contact personnel (Norway)',
    'observations.controlType.fieldVerificationCarnivoreContact.no': 'Observasjonen er kontrollert av rovviltkontakt',
    'observations.controlType.fieldVerificationCarnivoreContact.sv': 'Observationen är kontrollerad av rovviltkontakt',
    'observations.controlType.fieldVerificationSNO.en':
        'The observation has been inspected by the Norwegian Nature Inspectorate (Norway)',
    'observations.controlType.fieldVerificationSNO.no': 'Observasjonen er kontrollert av Statens Naturoppsyn',
    'observations.controlType.fieldVerificationSNO.sv': 'Observationen är kontrollerad av Statens Naturoppsyn (Norge)',
    'observations.controlType.photograph.en': 'The observation was inspected via photo',
    'observations.controlType.photograph.no': 'Observasjonen er kontrollert ved hjelp av foto',
    'observations.controlType.photograph.sv': 'Observationen är kontrollerad med hjälp av foto',
    'observations.controlType.notVerified.en': 'The observation has not been inspected',
    'observations.controlType.notVerified.no': 'Observasjonen er ikke kontrollert',
    'observations.controlType.notVerified.sv': 'Observationen är inte kontrollerad',
    'observations.controlType.interview.en': 'The observation was inspected via interview or letter',
    'observations.controlType.interview.no': 'Observasjonen er kontrollert ved hjelp av intervju eller brev',
    'observations.controlType.interview.sv': 'Observationen är kontrollerad via intervju eller brev',
    'observations.controlType.laboratory.en': 'The observation was inspected in a laboratory',
    'observations.controlType.laboratory.no': 'Observasjonen er kontrollert i laboratorium',
    'observations.controlType.laboratory.sv': 'Observationen är kontrollerat av laboratorium',
    'observations.controlType.fieldVerificationLST.en':
        'The observation has been inspected by the County Administrative Board (Sweden)',
    'observations.controlType.fieldVerificationLST.no': 'Observasjonen er kontrollert av Länsstyrelsen',
    'observations.controlType.fieldVerificationLST.sv': 'Observationen är kontrollerad av Länsstyrelsen',
    'observations.controlType.fieldVerificationSamiVillage.en':
        'The observation has been inspected by a Saami village (Sweden)',
    'observations.controlType.fieldVerificationSamiVillage.no': 'Observasjonen er kontrollert av sameby',
    'observations.controlType.fieldVerificationSamiVillage.sv':
        'Observationen är kontrollerad av samebyns inventeringssamordnare',
    'observations.controlType.GPS.en': 'The observation has been inspected via GPS- or radio tracking',
    'observations.controlType.GPS.no': 'Observasjonen er kontrollert ved hjelp av GPS eller radiopeiling',
    'observations.controlType.GPS.sv': 'Observationen är kontrollerad med hjälp av gps eller radiopejling',
    'observations.observationType.en': 'Observation types',
    'observations.observationType.no': 'Observasjonstyper',
    'observations.observationType.sv': 'Observationer',
    'observationsFilter.all.en': 'All',
    'observationsFilter.all.no': 'Alle',
    'observationsFilter.all.sv': 'Alla',
    'observationsFilter.onlyOffspring.en': 'Only offspring',
    'observationsFilter.onlyOffspring.no': 'Kun yngling',
    'observationsFilter.onlyOffspring.sv': 'Endast observationer av unge/ungar',
    'refund.reindeer.en': 'Reindeer',
    'refund.reindeer.no': 'Rein',
    'refund.reindeer.sv': 'Ren',
    'refund.reindeerAllAreas.en': 'All reindeer husbandry areas in Norway',
    'refund.reindeerAllAreas.no': 'Alle reinbeiteområder i Norge',
    'refund.reindeerAllAreas.sv': 'Alla renbetesområden i Norge',
    'refund.reindeerAreaDistrict.en': 'Reindeer husbandry district or area',
    'refund.reindeerAreaDistrict.no': 'Reinbeitedistrikt eller område',
    'refund.reindeerAreaDistrict.sv': 'Renbetesdistrikt eller område',
    'refund.graphicalImage.en': 'Graphical image of ',
    'refund.graphicalImage.no': 'Grafisk fremstilling av ',
    'refund.graphicalImage.sv': 'Grafisk framställning av ',
    'refund.sheepCompensationGraphTitle.en': 'Normal loss / Total compensated / Total loss ',
    'refund.sheepCompensationGraphTitle.no': 'Normaltap / Antall erstattet / Totalt tap ',
    'refund.sheepCompensationGraphTitle.sv': 'Normalförlust / Antal som kompenserats / Total förlust ',
    'refund.reindeerCompensationGraphTitle.en': 'Total compensated / Applied loss / Total loss ',
    'refund.reindeerCompensationGraphTitle.no': 'Antall erstattet / Omsøkt tap / Totalt tap ',
    'refund.reindeerCompensationGraphTitle.sv': 'Antal som kompenserats / Ansökt förlust / Total förlust ',
    'refund.compensationAmountGraphTitle.en': 'Compensation amount per area',
    'refund.compensationAmountGraphTitle.no': 'Erstatningsbeløp per område',
    'refund.compensationAmountGraphTitle.sv': 'Ersättningsbelopp per område',
    'refund.normalLoss.en': 'Normal loss',
    'refund.normalLoss.no': 'Normaltap',
    'refund.normalLoss.sv': 'Normalförlust',
    'refund.totalCompensated.en': 'Total compensated',
    'refund.totalCompensated.no': 'Antall erstattet',
    'refund.totalCompensated.sv': 'Antal som kompenserats',
    'refund.totalLoss.en': 'Total loss',
    'refund.totalLoss.no': 'Totalt tap',
    'refund.totalLoss.sv': 'Total förlust',
    'refund.appliedLoss.en': 'Applied loss',
    'refund.appliedLoss.no': 'Omsøkt tap',
    'refund.appliedLoss.sv': 'Ansökt förlust',
    'refund.compensationAmount.en': 'Compensation amount',
    'refund.compensationAmount.no': 'Erstatnings\u00ADbeløp', // \u00AD er unicode character for soft hyphen
    'refund.compensationAmount.sv': 'Kompensations\u00ADbelopp (NOK)', // \u00AD er unicode character for soft hyphen
    'refund.sheepCompensation.en': 'Sheep compensation',
    'refund.sheepCompensation.no': 'Sauerstatning',
    'refund.sheepCompensation.sv': 'Fårkompensation',
    'refund.reindeerCompensation.en': 'Reindeer compensation',
    'refund.reindeerCompensation.no': 'Reinerstatning',
    'refund.reindeerCompensation.sv': 'Renkompensation',
    'refund.area.en': 'Area',
    'refund.area.no': 'Område',
    'refund.area.sv': 'Område',
    'refund.compensated.en': 'Compensated',
    'refund.compensated.no': 'Erstattet',
    'refund.compensated.sv': 'Ersatt',
    'refund.numberOfApplications.en': 'Number of applications',
    'refund.numberOfApplications.no': 'Antall søknader',
    'refund.numberOfApplications.sv': 'Antal ansökningar',
    'refund.lamb.en': 'Lamb',
    'refund.lamb.no': 'Lam',
    'refund.lamb.sv': 'Lamm',
    'refund.sheep.en': 'Sheep',
    'refund.sheep.no': 'Sau',
    'refund.sheep.sv': 'Får',
    'refund.species.en': 'Species',
    'refund.species.no': 'Art',
    'refund.species.sv': 'Art',
    'refund.compensatedLamb.en': 'Lamb compensated ',
    'refund.compensatedLamb.no': 'Antall lam erstattet',
    'refund.compensatedLamb.sv': 'Antal lamm som ersatts',
    'refund.compensatedSheep.en': 'Sheep compensated ',
    'refund.compensatedSheep.no': 'Antall sau erstattet',
    'refund.compensatedSheep.sv': 'Antal får som ersatts',
    'refund.compensatedCalf.en': 'Reindeer calf compensated ',
    'refund.compensatedCalf.no': 'Antall kalv erstattet',
    'refund.compensatedCalf.sv': 'Antal kalvar som ersatts',
    'refund.compensatedBull.en': 'Reindeer bull compensated ',
    'refund.compensatedBull.no': 'Antall okserein erstattet',
    'refund.compensatedBull.sv': 'Antal sarvar som ersatts',
    'refund.compensatedDoe.en': 'Reindeer doe compensated ',
    'refund.compensatedDoe.no': 'Antall simlerein erstattet',
    'refund.compensatedDoe.sv': 'Antal vajor som ersatts',
    'refund.compensatedDriving.en': 'Reindeer driving compensated ',
    'refund.compensatedDriving.no': 'Antall kjørerein erstattet',
    'refund.compensatedDriving.sv': 'Antal dragrenar som ersatts',
    'refund.compensatedTotal.en': 'Total compensated',
    'refund.compensatedTotal.no': 'Totalt erstattet',
    'refund.compensatedTotal.sv': 'Totalt som ersätts',
    'refund.compensatedPercent.en': 'Share of total loss',
    'refund.compensatedPercent.no': 'Andel av totalt tap',
    'refund.compensatedPercent.sv': 'Andel av total förlust',
    'refund.compensationCarnivoreTitle.en': 'Compensation per carnivore',
    'refund.compensationCarnivoreTitle.no': 'Antall dyr erstattet og skadevolder',
    'refund.compensationCarnivoreTitle.sv': 'Ersättning fördelat på skadegörare',
    'refund.calf.en': 'Calf',
    'refund.calf.no': 'Kalv',
    'refund.calf.sv': 'Kalv',
    'refund.bull.en': 'Bull',
    'refund.bull.no': 'Okserein',
    'refund.bull.sv': 'Sarv',
    'refund.doe.en': 'Doe',
    'refund.doe.no': 'Simlerein',
    'refund.doe.sv': 'Vaja',
    'refund.driving.en': 'Driving',
    'refund.driving.no': 'Kjørerein',
    'refund.driving.sv': 'Dragren',
    'report.fullscreen.en': 'Fullscreen',
    'report.fullscreen.no': 'Fullskjerm',
    'report.fullscreen.sv': 'Helskärm',
    'report.toTopOfPage.en': 'To top of page',
    'report.toTopOfPage.no': 'Til toppen av siden',
    'report.toTopOfPage.sv': 'Till sidans topp',
    'searchfilter.geography.en': 'Geography',
    'searchfilter.geography.no': 'Geografi',
    'searchfilter.geography.sv': 'Geografiskt område',
    'searchfilter.allAreas.en': 'Norway, Sweden and Finland',
    'searchfilter.allAreas.no': 'Norge, Sverige og Finland',
    'searchfilter.allAreas.sv': 'Norge, Sverige och Finland',
    'searchfilter.selectCarnivore.en': 'Select carnivore to show in the map',
    'searchfilter.selectCarnivore.no': 'Velg rovdyr for å vise i kartet',
    'searchfilter.selectCarnivore.sv': 'Välj rovdjur som ska visas på kartan',
    'searchfilter.registrations.en': 'Registrations',
    'searchfilter.registrations.no': 'Registreringer',
    'searchfilter.registrations.sv': 'Registreringar',
    'searchfilter.messageAutosearch.en': 'Changing the search criterias will automatically update the map',
    'searchfilter.messageAutosearch.no': 'Kartet oppdateres automatisk ved endring av søkekriteriene i dette panelet',
    'searchfilter.messageAutosearch.sv': 'Kartan uppdateras automatiskt när sökkriterierna i denna panel ändras',
    'searchableSelect.locationInputPlaceholder.en': 'Country, region, county or municipality',
    'searchableSelect.locationInputPlaceholder.no': 'Land, region, fylke eller kommune',
    'searchableSelect.locationInputPlaceholder.sv': 'Land, län, kommun och förvaltningsområde',
    'searchableSelect.locationSearchInputPlaceholder.en': 'Country, county or municipality',
    'searchableSelect.locationSearchInputPlaceholder.no': 'Land, fylke eller kommune',
    'searchableSelect.locationSearchInputPlaceholder.sv': 'Land, län, kommun och förvaltningsområde',
    'searchableSelect.noOptionsMessage.en': 'No options',
    'searchableSelect.noOptionsMessage.no': 'Ingen valg',
    'searchableSelect.noOptionsMessage.sv': 'Ingen vald',
    'searchbar.placeholder.en': 'Search by individual, ID or barcode',
    'searchbar.placeholder.no': 'Søk på individ, ID eller strekkode',
    'searchbar.placeholder.sv': 'Sök efter individ, ID eller streckkod',
    'searchbar.label.en': 'Carnivore individual and ID-search',
    'searchbar.label.no': 'Individ og ID-søk',
    'searchbar.label.sv': 'Individ och ID-sök',
    'searchresultItem.descriptionText.en': 'Injured by',
    'searchresultItem.descriptionText.no': 'Skadet av',
    'searchresultItem.descriptionText.sv': 'Skadad av',
    'searchresultList.hits.en': 'hits',
    'searchresultList.hits.no': 'treff',
    'searchresultList.hits.sv': 'träff',
    'searchresultList.loading.en': 'Loading',
    'searchresultList.loading.no': 'Laster',
    'searchresultList.loading.sv': 'Laddar',
    'searchresultList.noResult.en': 'No hits',
    'searchresultList.noResult.no': 'Søket ga ingen resultater',
    'searchresultList.noResult.sv': 'Ingen träff',
    'searchresultList.showMore.en': 'Show more',
    'searchresultList.showMore.no': 'Vis flere',
    'searchresultList.showMore.sv': 'Visa fler',
    'searchresultList.showResults.en': 'Showing results',
    'searchresultList.showResults.no': 'Viser resultat',
    'searchresultList.showResults.sv': 'Visar resultat',
    'searchresultList.showFilter.en': 'Show searchfilter',
    'searchresultList.showFilter.no': 'Vis søkefilter',
    'searchresultList.showFilter.sv': 'Visa sökfilter',
    'searchresultList.showMap.en': 'Show map',
    'searchresultList.showMap.no': 'Vis kart',
    'searchresultList.showMap.sv': 'Visa karta',
    'searchresultList.ofTotal.en': 'of',
    'searchresultList.ofTotal.no': 'av',
    'searchresultList.ofTotal.sv': 'av',
    'searchresultList.backToSearch.en': 'Back to search',
    'searchresultList.backToSearch.no': 'Tilbake til søk',
    'searchresultList.backToSearch.sv': 'Tillbaka till sök',
    'searchresult.mailBody.en': 'This is a link to Rovbase%0d%0a',
    'searchresult.mailBody.no': 'Her følger link til Rovbase%0d%0a',
    'searchresult.mailBody.sv': 'Länk till Rovbase%0d%0a',
    'searchType.barcode.en': 'barcode',
    'searchType.barcode.no': 'strekkoden',
    'searchType.barcode.sv': 'streckkoden',
    'searchType.id.en': 'ID',
    'searchType.id.no': 'ID',
    'searchType.id.sv': 'ID',
    'searchType.search.en': 'search',
    'searchType.search.no': 'søket',
    'searchType.search.sv': 'söket',
    'shareSite.feedback.en': 'Link is copied to clipboard',
    'shareSite.feedback.no': 'Lenken er kopiert til utklippstavlen',
    'shareSite.feedback.sv': 'Kopierat till urklipp',
    'shareSite.share.en': 'Share',
    'shareSite.share.no': 'Del',
    'shareSite.share.sv': 'Dela',
    'sidepanel.resultList.en': 'Show results in list',
    'sidepanel.resultList.no': 'Vis resultat i liste',
    'sidepanel.resultList.sv': 'Visa resultat i listan',
    'sidepanel.resultMap.en': 'Show results in map',
    'sidepanel.resultMap.no': 'Vis resultat i kart',
    'sidepanel.resultMap.sv': 'Visa resultat i karta',
    'systemvarsel.tileLoadError.no': 'Vi opplever dessverre problemer med å vise enkelte bakgrunnskart. Beklager de ulempene dette medfører',
    'systemvarsel.tileLoadError.en': 'Unfortunately, we are experiencing problems with displaying some background maps. Sorry for the inconvenience this causes',
    'systemvarsel.tileLoadError.sv': 'Vi opplever dessverre problemer med å vise enkelte bakgrunnskart. Beklager de ulempene dette medfører',
    'layerSelector.beitelag.en': 'Beitelag map',
    'layerSelector.beitelag.no': 'Beitelagskart',
    'layerSelector.beitelag.sv': 'Beteslagskarta',
    'layerSelector.color.en': 'Color',
    'layerSelector.color.no': 'Farge',
    'layerSelector.color.sv': 'Färg',
    'layerSelector.forvaltningsomrade.en': 'Management areas',
    'layerSelector.forvaltningsomrade.no': 'Forvaltningsområder',
    'layerSelector.forvaltningsomrade.sv': 'Förvaltningsområden',
    'layerSelector.maptype.en': 'Map type',
    'layerSelector.maptype.no': 'Karttype',
    'layerSelector.maptype.sv': 'Kartskikt',
    'layerSelector.mapdetails.en': 'Map details',
    'layerSelector.mapdetails.no': 'Kartdetaljer',
    'layerSelector.mapdetails.sv': 'Kartdetaljer',
    'layerSelector.reinbeitedistrikt.en': 'Reindeer husbandry district',
    'layerSelector.reinbeitedistrikt.no': 'Reinbeitedistrikt',
    'layerSelector.reinbeitedistrikt.sv': 'Renbetesdistrikt',
    'layerSelector.reinbeiteomrade.en': 'Reindeer husbandry area',
    'layerSelector.reinbeiteomrade.no': 'Reinbeiteområde',
    'layerSelector.reinbeiteomrade.sv': 'Renbetesområden',
    'layerSelector.reindrift.en': 'Reindeer husbandry',
    'layerSelector.reindrift.no': 'Reindrift',
    'layerSelector.reindrift.sv': 'Renskötsel',
    'layerSelector.samebygrense.en': 'Sami villages',
    'layerSelector.samebygrense.no': 'Samebygrense',
    'layerSelector.samebygrense.sv': 'Samebygränser',
    'layerSelector.satellite.en': 'Satellite',
    'layerSelector.satellite.no': 'Flyfoto',
    'layerSelector.satellite.sv': 'Ortofoto',
    'layerSelector.managementAreas.en': 'Management areas',
    'layerSelector.managementAreas.no': 'Forvaltningsområder',
    'layerSelector.managementAreas.sv': 'Förvaltningsområden (Norge)',
    'layerSelector.basemap.en': 'Basemap',
    'layerSelector.basemap.no': 'Bakgrunnskart',
    'layerSelector.basemap.sv': 'Bakgrundskarta',
    'layerSelector.samebygrense.info.en': 'Boundary lines between Saami villages (Sweden)' ,
    'layerSelector.samebygrense.info.no': 'Grenselinjer mellom samebyer', 
    'layerSelector.samebygrense.info.sv': 'Byskiljande gränslinjer mellan samebyar som i administrativ ordning fastställts och vunnit laga kraft' ,
    'theCarnivore.bear.en': 'The bear',
    'theCarnivore.bear.no': 'Bjørnen',
    'theCarnivore.bear.sv': 'Björnen',
    'theCarnivore.goldenEagle.en': 'The golden eagle',
    'theCarnivore.goldenEagle.no': 'Kongeørnen',
    'theCarnivore.goldenEagle.sv': 'Kungsörnen',
    'theCarnivore.lynx.en': 'The lynx',
    'theCarnivore.lynx.no': 'Gaupen',
    'theCarnivore.lynx.sv': 'Lodjuret',
    'theCarnivore.unknownProtected.en': 'Unknown protected',
    'theCarnivore.unknownProtected.no': 'Ukjent fredet',
    'theCarnivore.unknownProtected.sv': 'Okänt fredat',
    'theCarnivore.wolf.en': 'The wolf',
    'theCarnivore.wolf.no': 'Ulven',
    'theCarnivore.wolf.sv': 'Vargen',
    'theCarnivore.wolverine.en': 'The wolverine',
    'theCarnivore.wolverine.no': 'Jerven',
    'theCarnivore.wolverine.sv': 'Järven',
    'theCarnivore.bear.female.en': 'the female bear',
    'theCarnivore.bear.female.no': 'hunnbjørnen',
    'theCarnivore.bear.female.sv': 'björnhona',
    'theCarnivore.bear.male.en': 'the male bear',
    'theCarnivore.bear.male.no': 'hannbjørnen',
    'theCarnivore.bear.male.sv': 'björnhane',
    'theCarnivore.bear.unknown.en': 'the bear',
    'theCarnivore.bear.unknown.no': 'bjørnen',
    'theCarnivore.bear.unknown.sv': 'björnen',
    'theCarnivore.lynx.female.en': 'the female lynx',
    'theCarnivore.lynx.female.no': 'hunngaupen',
    'theCarnivore.lynx.female.sv': 'lodjurhona',
    'theCarnivore.lynx.male.en': 'the male lynx',
    'theCarnivore.lynx.male.no': 'hanngaupen',
    'theCarnivore.lynx.male.sv': 'lodjurhane',
    'theCarnivore.lynx.unknown.en': 'the lynx',
    'theCarnivore.lynx.unknown.no': 'gaupen',
    'theCarnivore.lynx.unknown.sv': 'lodjuret',
    'theCarnivore.wolf.female.en': 'the female wolf',
    'theCarnivore.wolf.female.no': 'hunnulven',
    'theCarnivore.wolf.female.sv': 'varghona',
    'theCarnivore.wolf.male.en': 'the male wolf',
    'theCarnivore.wolf.male.no': 'hannulven',
    'theCarnivore.wolf.male.sv': 'varghane',
    'theCarnivore.wolf.unknown.en': 'the wolf',
    'theCarnivore.wolf.unknown.no': 'ulven',
    'theCarnivore.wolf.unknown.sv': 'vargen',
    'theCarnivore.wolverine.female.en': 'the female wolverine',
    'theCarnivore.wolverine.female.no': 'hunnjerven',
    'theCarnivore.wolverine.female.sv': 'järvhona',
    'theCarnivore.wolverine.male.en': 'the male wolverine',
    'theCarnivore.wolverine.male.no': 'hannjerven',
    'theCarnivore.wolverine.male.sv': 'järvhane',
    'theCarnivore.wolverine.unknown.en': 'the wolverine',
    'theCarnivore.wolverine.unknown.no': 'jerven',
    'theCarnivore.wolverine.unknown.sv': 'järven',
    'tooltip.dnaAnalysed.en': 'The sample has been analysed and is in form of a',
    'tooltip.dnaAnalysed.no': 'Prøven er analysert og tilhører en',
    'tooltip.dnaAnalysed.sv': 'Analysresultatet visar att det är en',
    'tooltip.dnaAnalysed.nonFunctioning.en': 'The sample has been analysed, but was non-functioning.',
    'tooltip.dnaAnalysed.nonFunctioning.no': 'Prøven er analysert, men ikke-fungerende.',
    'tooltip.dnaAnalysed.nonFunctioning.sv': 'DNA-analysen gav inget resultat.',
    'tooltip.dnaBarcode.en': 'The DNA-sample barcode is',
    'tooltip.dnaBarcode.no': 'Strekkoden for DNA-prøven er',
    'tooltip.dnaBarcode.sv': 'Streckkoden för DNA-provet är',
    'tooltip.dnaIdentity.en': 'is identified as',
    'tooltip.dnaIdentity.no': 'er identifisert som',
    'tooltip.dnaIdentity.sv': 'är identifierad som',
    'tooltip.dnafrom.en': 'from',
    'tooltip.dnafrom.no': 'fra',
    'tooltip.dnafrom.sv': 'med art bedömd i fält som',
    'tooltip.dnaphrase.en': 'A DNA-sample is submitted as',
    'tooltip.dnaphrase.no': 'En DNA-prøve er levert inn som',
    'tooltip.dnaphrase.sv': 'Ett DNA-prov i form av',
    'tooltip.east.en': 'East',
    'tooltip.east.no': 'Øst',
    'tooltip.east.sv': 'Öst',
    'tooltip.individual.en': 'Carnivore individual ',
    'tooltip.individual.no': 'Rovdyrindivid ',
    'tooltip.individual.sv': 'Rovdjursindivid ',
    'tooltip.individualPhrase.en': ' has been at the location.',
    'tooltip.individualPhrase.no': ' har vært på stedet.',
    'tooltip.individualPhrase.sv': ' har varit på platsen.',
    'tooltip.nextPage.en': 'Next page',
    'tooltip.nextPage.no': 'Neste side',
    'tooltip.nextPage.sv': 'Nästa sida',
    'tooltip.nextPost.en': 'Next post',
    'tooltip.nextPost.no': 'Neste post',
    'tooltip.nextPost.sv': 'Nästa post',
    'tooltip.north.en': 'North',
    'tooltip.north.no': 'Nord',
    'tooltip.north.sv': 'Norr',
    'tooltip.of.en': 'of',
    'tooltip.of.no': 'av',
    'tooltip.of.sv': 'av',
    'tooltip.previousPage.en': 'Previous page',
    'tooltip.previousPage.no': 'Forrige side',
    'tooltip.previousPage.sv': 'Föregående sida',
    'tooltip.previousPost.en': 'Previous post',
    'tooltip.previousPost.no': 'Forrige post',
    'tooltip.previousPost.sv': 'Föregående post',
    'tooltip.sampleType.blood.en': 'Blood',
    'tooltip.sampleType.blood.no': 'Blod',
    'tooltip.sampleType.blood.sv': 'Blod',
    'tooltip.sampleType.hair.en': 'Hair',
    'tooltip.sampleType.hair.no': 'Hår',
    'tooltip.sampleType.hair.sv': 'Hår',
    'tooltip.sampleType.saliva.en': 'Saliva',
    'tooltip.sampleType.saliva.no': 'Spytt',
    'tooltip.sampleType.saliva.sv': 'Saliv',
    'tooltip.sampleType.scat.en': 'Scat',
    'tooltip.sampleType.scat.no': 'Ekskrement',
    'tooltip.sampleType.scat.sv': 'Spillning',
    'tooltip.sampleType.scentMarking.en': 'Scent marking',
    'tooltip.sampleType.scentMarking.no': 'Sekret',
    'tooltip.sampleType.scentMarking.sv': 'Sekret',
    'tooltip.sampleType.tissue.en': 'Tissue',
    'tooltip.sampleType.tissue.no': 'Vev',
    'tooltip.sampleType.tissue.sv': 'Vävnad',
    'tooltip.sampleType.urine.en': 'Urine',
    'tooltip.sampleType.urine.no': 'Urin',
    'tooltip.sampleType.urine.sv': 'Urin',
    'tooltip.copyLink.en': 'Copy link',
    'tooltip.copyLink.no': 'Kopier lenke',
    'tooltip.copyLink.sv': 'Kopiera länk',
    'tooltip.mailLink.en': 'Send by e-mail',
    'tooltip.mailLink.no': 'Send på e-post',
    'tooltip.mailLink.sv': 'Skicka som e-post',
    'tooltip.animalDamaged.en': 'One/an animal is ',
    'tooltip.animalDamaged.no': 'Ett dyr er ',
    'tooltip.animalDamaged.sv': 'Ett djur ',
    'compensation.header.en': 'Compensation for ',
    'compensation.header.no': 'Erstatning for ',
    'compensation.header.sv': 'Ersättning för rovdjurens skador på ',
    'compensation.messageAutosearch.en': 'Changing the search criterias will automatically update the page',
    'compensation.messageAutosearch.no': 'Siden oppdateres automatisk ved endring av søkekriteriene i dette panelet',
    'compensation.messageAutosearch.sv': 'Sidan uppdateras automatiskt när sökkriterierna i denna panel ändras',
    'compensation.sheepSeason.en': 'Season',
    'compensation.sheepSeason.no': 'Beitesesong',
    'compensation.sheepSeason.sv': 'Betessäsong',
    'compensation.reindeerSeason.en': 'Season',
    'compensation.reindeerSeason.no': 'Reindriftsår',
    'compensation.reindeerSeason.sv': 'Renskötselår',
    'compensation.from.en': 'From',
    'compensation.from.no': 'Fra',
    'compensation.from.sv': 'Från',
    'compensation.to.en': 'To',
    'compensation.to.no': 'Til',
    'compensation.to.sv': 'Till',
    'damageState.dead.en': 'killed',
    'damageState.dead.no': 'drept',
    'damageState.dead.sv': 'har dödats',
    'damageState.damagedAndEuthanized.en': 'euthanized',
    'damageState.damagedAndEuthanized.no': 'skadd og avlivet',
    'damageState.damagedAndEuthanized.sv': 'har avlivats',
    'damageState.damagedNotEuthanized.en': 'injured',
    'damageState.damagedNotEuthanized.no': 'skadd og ikke avlivet',
    'damageState.damagedNotEuthanized.sv': 'har skadats',
    'damageState.missing.en': 'missing',
    'damageState.missing.no': 'savnet',
    'damageState.missing.sv': 'saknas',
    'inspectedBy.sno.en': 'The damage was inspected by SNO',
    'inspectedBy.sno.no': 'Skaden er undersøkt av SNO',
    'inspectedBy.sno.sv': 'Skadan är undersökt av SNO',
    'inspectedBy.sysselmester.en': 'The damage was inspected by Sysselmesteren',
    'inspectedBy.sysselmester.no': 'Skaden er undersøkt av Sysselmesteren',
    'inspectedBy.sysselmester.sv': 'Skadan är undersökt av Sysselmesteren',
    'inspectedBy.lansstyrelsen.en': 'The damage was inspected by Länsstyrelsen',
    'inspectedBy.lansstyrelsen.no': 'Skaden er undersøkt av Länsstyrelsen',
    'inspectedBy.lansstyrelsen.sv': 'Skadan är undersökt av länsstyrelsen',
    'inspectedBy.samiVillage.en': 'The damage was inspected by a sami village',
    'inspectedBy.samiVillage.no': 'Skaden er undersøkt av en sameby',
    'inspectedBy.samiVillage.sv': 'Skadan är undersökt av en sameby',
    'inspectedBy.notControlledInField.en': 'The damage was not controlled in field',
    'inspectedBy.notControlledInField.no': 'Rapport ikke kontrollert på stedet',
    'inspectedBy.notControlledInField.sv': 'Rapport om skada har inte följts upp på plats',

    'uu.close.en': 'Close',
    'uu.close.no': 'Lukk',
    'uu.close.sv': 'Stäng',
    'uu.clear.en': 'Clear',
    'uu.clear.no': 'Nullstill',
    'uu.clear.sv': 'Rensa',
    'uu.expand.en': 'expand',
    'uu.expand.no': 'utvid',
    'uu.expand.sv': 'expandera',
    'uu.icon.en': 'icon',
    'uu.icon.no': 'ikon',
    'uu.icon.sv': 'ikon',
    'uu.openInNew.en': 'open external link',
    'uu.openInNew.no': 'åpne lenke til ekstern side',
    'uu.openInNew.sv': 'öppna länk till extern sida',
    'uu.openNewWindow.en': 'opens an external link in a new window',
    'uu.openNewWindow.no': 'åpner ekstern side i et nytt vindu',
    'uu.openNewWindow.sv': 'öppnar extern sida i ett nytt fönster',
    'uu.openPanel.en': 'open sidepanel',
    'uu.openPanel.no': 'åpne sidepanel',
    'uu.openPanel.sv': 'öppna sidopanel',
    'uu.closePanel.en': 'close sidepanel',
    'uu.closePanel.no': 'lukke sidepanel',
    'uu.closePanel.sv': 'stänga sidopanel',
    
    'tooltip.label.en': 'Tips for the field',
    'tooltip.label.no': 'Tips for feltet',
    'tooltip.label.sv': 'Tips för fältet ',
    
};

const templates = {
    'dnaInfo.submitted.no': 'En DNA-prøve er levert inn som £observationType£ fra £art£.',
    'dnaInfo.submitted.sv': 'Ett DNA-prov i form av £observationType£ med art bedömd i fält som £art£.',
    'dnaInfo.submitted.en': 'A DNA-sample is submitted as £observationType£ from £art£.',
    'dnaInfo.analysed.no': 'Prøven er analysert og tilhører en £art£.',
    'dnaInfo.analysed.sv': 'Analysresultatet visar att det är en £art£.',
    'dnaInfo.analysed.en': 'The sample has been analysed and is in form of a £art£.',

    'searchresult.individDescription.en': 'Showing £count£ hit(s) on £individualType£ £individual£',
    'searchresult.individDescription.no': 'Viser £count£ treff på £individualType£ £individual£',
    'searchresult.individDescription.sv': 'Visar £count£ träff på £individualType£ £individual£',
    'searchresult.individDescription.origin.en': 'The animal is £origin£.',
    'searchresult.individDescription.origin.no': 'Dyret er £origin£.',
    'searchresult.individDescription.origin.sv': 'Djuret är £origin£.',
    'searchresult.individDescription.revir.en': ', born in the £revir£-territory',
    'searchresult.individDescription.revir.no': ', født i £revir£-reviret',
    'searchresult.individDescription.revir.sv': ', född i £revir£-reviret',
    'searchresult.specialSearchDescription.en': 'Showing £count£ hit(s) on £searchType£ £searchText£',
    'searchresult.specialSearchDescription.no': 'Viser £count£ treff på £searchType£ £searchText£',
    'searchresult.specialSearchDescription.sv': 'Visar £count£ träff på £searchType£ £searchText£',
    'searchresult.multipleElementsSearch.en': '£element1£, £element2£ and £count£ other elements',
    'searchresult.multipleElementsSearch.no': '£element1£, £element2£ og £count£ andre elementer',
    'searchresult.multipleElementsSearch.sv': '£element1£, £element2£ och £count£ andra sökord',

    'tooltip.observation.no': '£art£ er observert. £controlType£ Observasjonen er £evaluation£.',
    'tooltip.observation.sv': '£art£ har observerats. £controlType£ Observationen är £evaluation£.',
    'tooltip.observation.en': '£art£ has been observed. £controlType£ The observation is £evaluation£. ',
    'tooltip.observation.error.no': '£art£ er observert. £controlType£  Observasjonen er en feilmelding.',
    'tooltip.observation.error.sv': '£art£ har observerats. £controlType£ Observationen är en felrapport.',
    'tooltip.observation.error.en': '£art£ has been observed. £controlType£ The observation is an erroneous report.',

    'tooltip.damage.no': '£object£ £damageState£. £inspectedBy£. Skadeårsaken er £documentation£. ',
    'tooltip.damage.sv': '£object£ £damageState£. £inspectedBy£. Skadeorsaken är £documentation£. ',
    'tooltip.damage.en': '£object£ £damageState£. £inspectedBy£. The cause of the damage is £documentation£. ',
    'tooltip.damage.area.no': 'Skaden skjedde i £area£.',
    'tooltip.damage.area.sv': 'Skadan skedde i £area£.',
    'tooltip.damage.area.en': 'The damage occured at £area£.',

    'tooltip.dead.en': 'The animal\'s sex was £sex£, weight was £weight£, and age was £age£. ',
    'tooltip.dead.no': 'Dyrets kjønn var £sex£, vekt var £weight£ og alder var £age£. ',
    'tooltip.dead.sv': 'Djurets kön var £sex£, vikt var £weight£ och ålder är £age£. ',
    'tooltip.dead.identity.en': '£theAnimal£ is identified as ',
    'tooltip.dead.identity.no': '£theAnimal£ er identifisert som ',
    'tooltip.dead.identity.sv': '£theAnimal£ är identifierad som ',

    'tooltip.mailBody.en': 'This is a link to "£title£" in Rovbase%0d%0a',
    'tooltip.mailBody.no': 'Her følger link til "£title£" i Rovbase%0d%0a',
    'tooltip.mailBody.sv': 'Länk till "£title£" i Rovbase%0d%0a',

    'dateLabel.dateMustBeAfter.en': 'Date must be after £date£',
    'dateLabel.dateMustBeAfter.no': 'Dato må være etter £date£',
    'dateLabel.dateMustBeAfter.sv': 'Datum får inte vara före £date£',
    'dateLabel.dateMustBeBefore.en': 'Date must be before £date£',
    'dateLabel.dateMustBeBefore.no': 'Dato må være før £date£',
    'dateLabel.dateMustBeBefore.sv': 'Datum får inte vara efter £date£',
};

// Hjelpefunksjoner
export type LangProp = {
    language: Language;
};

const get = (langId: Language, id: string): string => {
    const stringId = `${id}.${langId}`;
    if (i18n[stringId]) {
        return i18n[stringId];
    } else {
        /* eslint-disable no-console */
        /* eslint-disable no-undef */
        console.warn(`Could not load language for ${id}`);
        return '';
    }
};

const getTemplate = (langId: Language, id: string, input: any): string => {
    const stringId = `${id}.${langId}`;
    if (templates[stringId]) {
        const test = templates[stringId].replace(RegExp('£\\w*£', 'gi'), function(i: string): string {
            let a = i.slice(1, i.length - 1);
            if (input[a] !== undefined) {
                a = input[a];
            }
            return a;
        });
        return test;
    } else {
        /* eslint-disable no-console */
        /* eslint-disable no-undef */
        console.warn(`Could not load language for ${id}`);
        return '';
    }
};

type StateProps = {
    language: Language;
};

type OwnProps = {
    textId: string;
};

type Props = StateProps & OwnProps;

const getTest = (props: Props): string => {
    const stringId = `${props.textId}.${props.language}`;
    if (i18n[stringId]) {
        return i18n[stringId];
    } else {
        /* eslint-disable no-console */
        /* eslint-disable no-undef */
        console.warn(`Could not load language for ${props.textId}`);
        return '';
    }
};

const mapStateToProps = (appState: ApplicationState): StateProps => {
    return {
        language: appState.settings.language,
    };
};

// export const dd = connect(mapStateToProps)(getTest);

export const connectedContainer = connect(mapStateToProps, { getTest });

export function useLanguage(lang?: Language): (str: string) => string {
    const language: Language = lang || useSelector((state: ApplicationState) => state.settings.language, shallowEqual);
    return curry(get)(language);
}

export function useLanguageTemplate(): (str: string, input: any) => string {
    const language = useSelector((state: ApplicationState) => state.settings.language, shallowEqual);
    return curry(getTemplate)(language);
}

export const L = curry(get);
export const LT = curry(getTemplate);
export default L;

// export type TranslationContextValue = {
//     translation: Translation;
//     setLanguage: (language: Language) => void;
// }

// export const TranslationContext = createContext<TranslationContextValue>(
//     defaultContext()
// );

// export const useTranslation = (): TranslationContextValue => useContext(TranslationContext);
